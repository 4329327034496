var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.company?.name
    ? _c(
        "div",
        { attrs: { id: "heading" } },
        [
          _c("stageline-back-button", {
            attrs: { text: "Return to Overview" },
          }),
          _c("div", { staticClass: "company-name mt-2" }, [
            _vm._v("\n    " + _vm._s(_vm.company.name) + "\n  "),
          ]),
          _c("div", { staticClass: "jurisdiction" }, [
            _vm._v(
              "\n    " +
                _vm._s(
                  _vm.company?.domestic_registration?.jurisdiction
                    ?.state_province_region || "Unknown Jurisdiction"
                ) +
                "\n  "
            ),
          ]),
          _c("hr", { staticClass: "mx-0 my-2" }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }