<template>
  <div v-if="company?.name" id="heading">
    <stageline-back-button text="Return to Overview" />

    <div class="company-name mt-2">
      {{ company.name }}
    </div>
    <div class="jurisdiction">
      {{ company?.domestic_registration?.jurisdiction?.state_province_region || 'Unknown Jurisdiction' }}
    </div>
    <hr class="mx-0 my-2">
  </div>
</template>
<script>

export default {
  name: 'StageViewer',
  components: {
    StagelineBackButton: () => import('@/components/StagelineV2/shared/StagelineBackButton.vue'),
  },
  props: {
    company: {
      type: Object,
      default: () => ({}),
    },
  },
}

</script>
<style scoped lang="scss">
#heading {
  .company-name {
    color: #000864;
    display: block;
    text-align: left;
    font-weight: 700;
    font-size: 1.5em;
    width: 100%;
  }

  .jurisdiction {
    font-style: italic;
    color: #BABDC0;
  }
}
</style>
